.mini-navbar{
    width: 4.6rem;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top:4rem;
    left:0;
    padding-top: 3px;
    background-color: rgb(255, 255, 255);
}
.mini-nav-div{
    /* border: 1px solid #000; */
    height: 5rem;
    padding-top: 1rem;
    transition: 0.3s ease-in-out;
}
.mini-nav-div:hover{
    background-color: #eee;
}
.mini-nav-icon{
    font-size: 1.2rem;
    display: block;
    text-align: center;
    margin: 0 auto;
}
.mini-nav-text{
    font-size: 0.8rem;
    display: block;
    text-align: center;
}